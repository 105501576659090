<template>
<div class="doc-container">
	<div class="d-flex">
        <div class="flex-grow-1">
			<CCard class="p-0">
				<CCardHeader>
					<div class="d-flex">
						<div class="flex-grow-1">
							<h5>Template Berkas</h5>
						</div>
						<div class="flex-grow-0 ml-10">
							<CButton class="btn-sm text-white" color="danger" @click="showpopupModal(null)">
								Tambah Template
							</CButton>
						</div>
					</div>
				</CCardHeader>
				<CCardBody>
					<v-data-table
						:loading="loading"
						loading-text="Memuat Data..."
						:headers="modelTable.headers"
						:options.sync="optionsTable"
						:server-items-length="modelTable.totalData"
						:multi-sort="false"
						:items="itemsWithIndex"
						:single-expand="true"
						item-key="templateName"
						:footer-props="modelTable.footerProps"
						class="elevation-1">
							<template v-slot:item.actions="{ item }">
								<v-icon v-c-tooltip="'Buka detil template'" small class="text-info ml-1" @click="detailItem(item)">
									mdi-text-box
								</v-icon>
								<v-icon v-c-tooltip="'Tampilkan template'" small class="text-info ml-1" @click="previewItem(item)">
									mdi-file-find
								</v-icon>
								<v-icon v-c-tooltip="'Unduh template'" small class="text-info ml-1" @click="downloadItem(item)">
									mdi-download
								</v-icon>
								<v-icon v-c-tooltip="'Hapus data template'" small class="text-danger ml-3" @click="deleteItem(item)">
									mdi-delete
								</v-icon>
							</template>
							<template v-slot:no-data>
								<span>Data Tidak Ditemukan</span>
							</template>
					</v-data-table>
				</CCardBody>
			</CCard>
		</div>
		<div class="flex-grow-0 ml-20 d-none" style="width:500px">
			<CCard class="p-0">
				<CCardHeader>
					<h5>Pratinjau {{ previewDoc.title }}</h5>
				</CCardHeader>
				<vue-page-transition name="fade">
					<CCardBody v-if="previewDoc.isLoad">
						<VueDocPreview :value="previewDoc.content" type="office"/>
					</CCardBody>
					<CCardBody v-else>
						<div class="p-2 position-relative mb-10" style="min-height:50vh">
							<div v-if="previewDoc.isRendered" class="loader content-center p-2">
								<v-progress-circular class="loadingDoc" indeterminate color="red"></v-progress-circular>
							</div>
							<div v-else class="content-center p-2" style="min-height:50vh">
								<div>
									<font-awesome-icon icon="info-circle" class="text-info mb-20 mt-20"/><br/>
									Klik simbol <v-icon small>mdi-eye</v-icon> untuk memuat data.
								</div>
							</div>
						</div>
					</CCardBody>
				</vue-page-transition>
			</CCard>
		</div>
	</div>

	<!-- Popup Detail -->
	<CModal :show.sync="detailModalIsShow">
		<template #header>
			<h5 class="modal-title">Detil Template Surat</h5>
		</template>
			<CCardBody class="doc-activity-container p-2">
				<table class="table table-borderless">
					<tbody>
						<tr>
							<td width="125px">Nama Template</td>
							<td width="2px">:</td>
							<td>{{ detailData.templateName }}</td>
						</tr>
						<tr>
							<td width="125px">Kategori</td>
							<td width="2px">:</td>
							<td>{{ detailData.categoryName }}</td>
						</tr>
						<tr>
							<td width="125px">Dibuat Oleh</td>
							<td width="2px">:</td>
							<td>{{ detailData.createdBy }}</td>
						</tr>
						<tr>
							<td width="125px">Dibuat Pada</td>
							<td width="2px">:</td>
							<td>{{ detailData.createdAt }}</td>
						</tr>
						<tr>
							<td width="125px">Field</td>
							<td width="2px">:</td>
							<td>{{ detailData.fieldData }}</td>
						</tr>
					</tbody>
				</table>
			</CCardBody>
		<template #footer>
			<CButton @click="closePopupDetailModal" class="btn-sm">Tutup</CButton>
		</template>
	</CModal>
	<!-- Popup Preview -->
	<CModal :show.sync="previewModalIsShow">
		<template #header>
			<h5 class="modal-title">Tampilan Template Surat</h5>
		</template>
		<CCard class="p-9">
			<CCardBody class="position-relative" style="padding:0!important;">
				<!-- Document Preview -->
				<div class="doc-preview-container">
					<div v-if="attachment.loader == true" class="text-center p-3" style="margin-top: 100px">
						<font-awesome-icon size="2x" icon="spinner" spin class="mr-10 mb-20"/><br/>
						Memuat Lampiran...
					</div>
					<div v-else>
						<div v-if="attachment.thumbnails.length == 0" class="text-center p-3" style="margin-top: 100px">
							<font-awesome-icon icon="info-circle" size="2x" class="text-info mb-10"/><br/>
							Pratinjau Lampiran Tidak Tersedia
						</div>
						<img
							v-else
							:src="'data:image/jpeg;base64,'+
								attachment.thumbnails[attachment.pageActive - 1]"
							alt="Pratinjau Lampiran"
							class="img-fluid">
					</div>
				</div>
			</CCardBody>
			<CCardFooter class="p-0">
				<div class="text-center">
					<v-pagination
						v-model="attachment.pageActive"
						color="error"
						:length="attachment.pageCount"
						:total-visible="5"
						circle>
					</v-pagination>
				</div>
			</CCardFooter>
		</CCard>
		<template #footer>
			<CButton @click="closePopupPreviewModal" class="btn-sm">Tutup</CButton>
		</template>
	</CModal>

	<!-- Popup Modal & Input Form -->
	<CModal :show.sync="popupModal.isShow">
		<template #header>
			<h5 class="modal-title">{{ popupModal.modalTitle }}</h5>
		</template>
		<!-- Input Form -->
		<div class="p-1">
			<div role="group" class="form-group">
				<label class="typo__label">Kategori Template Berkas</label>
				<multiselect 
					v-model="modelForm.input.templateCategory"
					:options="modelForm.templateCategoryOption"
					:multiple="false"
					:allow-empty="false"
					:close-on-select="true"
					:clear-on-select="false"
					:is-valid="modelForm.validate.templateCategory"
					@input="validateForm('templateCategory', $event)"
					placeholder="Pilih Kategori Template Berkas">
				</multiselect>
			</div>
			<CInput
				v-model="modelForm.input.templateName"
				label="Nama Template Berkas"
				placeholder="Nama Template Berkas"
				:is-valid="modelForm.validate.templateName"
				@change="validateForm('templateName', $event)" />
			<div role="group" class="form-group">
				<label class="">Unggah Template Berkas</label>
				<CInputFile
					ref="templateFile"
					label="Pilih Berkas"
					accept=".docx"
					:placeholder="modelForm.placeholderFile"
					:custom="true"
					:is-valid="modelForm.validate.templateFile"
					@change="validateForm('templateFile', $event)" />
			</div>
		</div>
		<template #footer>
			<CButton @click="closePopupModal" class="btn-sm">Batal</CButton>
			<CButton @click="saveItem" color="success" class="btn-sm" :disabled="modelForm.submitBtn.disabled">
				<font-awesome-icon v-if="modelForm.submitBtn.loader" icon="spinner" spin/> {{ modelForm.submitBtn.text }}
			</CButton>
		</template>
	</CModal>
</div>
</template>

<script>
import '@/assets/plugins/docViewer';
import 'vue-multiselect/dist/vue-multiselect.min.css';
export default {
	names: 'Template',
	data () {
		const modelTable = {
			headers: [
				{ text: 'No.', value: 'index', sortable: false, align: 'center' },
				{ text: 'Kategori', value: 'templateCategory', sortable: true },
				{ text: 'Nama Template', value: 'templateName', sortable: true },
				{ text: 'Aksi', value: 'actions', sortable: false, align: 'center' }
			],
			footerProps: {
				'disable-items-per-page': true,
				'items-per-page-text': 'Data Ditampilkan'
			},
			totalData: 0,
			dataList: [],
			expandedTable: []
		}
		const popupModal = {
			isShow: false,
			modalTitle: 'Tambah Data Template'
		}
		const modelForm = {
			editedIndex: -1,
			templateCategoryOption: [],
			input: {
				templateCategory: null,
				templateName: null,
				templateFile: null
			},
			defaultValue: {
				templateCategory: [],
				templateName: '',
				templateFile: ''
			},
			validate: {
				templateCategory: null,
				templateName: null,
				templateFile: null
			},
			submitBtn: {
				loader: false,
                text: 'Simpan',
				disabled: true
			},
			placeholderFile: 'Unggah Berkas'
		}
		const previewDoc = {
			isRendered: false,
			isLoad: false,
			title: '',
			content: null
		}

		return {
			loading: true,
			modelTable,
			optionsTable: {},
			popupModal,
			modelForm,
			previewDoc,
			categoryList: [],
			previewModalIsShow: false,
			detailModalIsShow: false,
			attachment: {
                loader: false,
                docFileId: null,
                pageActive: 1,
                pageCount: 0,
                thumbnails: []
            },
			detailData: {
				templateName: "",
				categoryName: "",
				createdBy: "",
				createdAt: "",
				fieldData: ""
			}
		}
	},
	computed: {
		itemsWithIndex() {
			return this.modelTable.dataList.map(
				(items, index) => ({
				...items,
				index: index + 1
				}))
		}
	},
	watch: {
		optionsTable: {
			deep: true,
			handler () {
				this.getDataList()
					.then( data => {
						this.modelTable.dataList = data.items
						this.modelTable.totalData = data.total
						this.loading = false
					})
			}			
		}
    },
	mounted () {
		this.getDataList()
			.then( data => {
				this.modelTable.dataList = data.items
				this.modelTable.totalData = data.total
				this.loading = false
			})
		this.getTemplateCategoryOption()
	},
	methods: {
		getDataList () {
			this.loading = true
			return new Promise((resolve, reject) => {
				const { sortBy, sortDesc, page, itemsPerPage } = this.optionsTable

				this.$store.dispatch({
                    type: 'template/get',
                    pagination: {
                        limit: itemsPerPage,
                        offset: (page-1) * itemsPerPage
                    },
                    order: {
                        orderBy: (sortBy.length > 0) ? sortBy[0] : 'template_id',
                        orderType: (sortDesc.length > 0 && sortDesc[0])? 'desc' : 'asc'
                    },
                    filter: {
                        categoryId: '',
                        templateName: ''
                    }
                }).then( res => {
					let items = []
					let total = 0

					if(res){
						res.result.forEach((item) => {
							let rowData = {
								templateId: item.template_id,
								templateCategory: item.template_category,
								templateName: item.template_name
							}
							items.push(rowData)
						})
						total = res.total_count
					}
					
					setTimeout(() => {
						resolve({
							items,
							total
						})
					}, 1000)
				})
			})
		},
		getTemplateCategoryOption () {
			this.$store.dispatch(
				{
					type: 'document/getCategory',
					pagination: {
						limit: 50,
						offset: 0
					},
					order: {
						orderType: 'asc'
					}
				}
			).then( res => {
				let items = []
				res.result.forEach((item) => {
					items.push(item.category_name)
				})
				
				this.categoryList = res.result
				this.modelForm.templateCategoryOption = items
			})
		},
		showpopupModal (item) {
			this.popupModal.modalTitle = 'Tambah Data Template'
			this.modelForm.editedIndex = -1
			this.popupModal.isShow = true
		},
		closePopupModal () {
			this.modelForm.input = Object.assign({}, this.modelForm.defaultValue)
			this.modelForm.placeholderFile = 'Unggah Berkas'
			this.modelForm.validate = {
				templateCategory: null,
				templateName: null,
				templateFile: null
			}
			this.modelForm.submitBtn.disabled = true
			this.popupModal.isShow = false

			this.getDataList()
				.then( data => {
					this.modelTable.dataList = data.items
					this.modelTable.totalData = data.total
					this.loading = false
				})
		},
		validateForm (field, event) {
			if (field == 'templateCategory') {
				if (this.modelForm.input.templateCategory.length > 0) {
					this.modelForm.validate.templateCategory = true
				} else {
					this.modelForm.validate.templateCategory = false
					return false
				}
			}
			if (field == 'templateName') {
				if (this.modelForm.input.templateName != '') {
					this.modelForm.validate.templateName = true
				} else {
					this.modelForm.validate.templateName = false
					return false
				}
			}
			if (field == 'templateFile') {
				if (event.length > 0) {
					let fileCollection = []
					event.forEach((item) => {
						fileCollection.push(item.name)
					})
					this.modelForm.placeholderFile = fileCollection.join()
					this.modelForm.input.templateFile = event
					this.modelForm.validate.templateFile = true
				} else{
					this.modelForm.placeholderFile = 'Unggah Berkas'
					this.modelForm.validate.templateFile = false
					return false
				}
			}

			if (this.modelForm.validate.templateCategory &&
				this.modelForm.validate.templateName &&
				this.modelForm.validate.templateFile)
				this.modelForm.submitBtn.disabled = false
		},
		saveItem () {
			this.modelForm.submitBtn = {
                loader: true,
                text: 'Memproses...'
            }

			var category_name = this.modelForm.input.templateCategory
			var categoryId = this.categoryList.filter(data=> data.category_name == category_name)[0].category_id

			this.modelForm.input.categoryId = categoryId
			console.log(categoryId)
			this.$store.dispatch({
				type: 'template/create',
				formInput: this.modelForm.input
			}).then( res => {
				if(res.error != null) {
					this.$toasted.error('Tidak boleh ada spasi didalam field').goAway(3000)
					
					this.modelForm.submitBtn = {
						loader: false,
						text: 'Simpan'
					}
				} else {
				this.getDataList()
					.then( data => {
						this.modelTable.dataList = data.items
						this.modelTable.totalData = data.total
						
						this.$toasted.success('Sukses menambah data').goAway(3000)
						this.modelForm.submitBtn = {
							loader: false,
							text: 'Simpan'
						}
						setTimeout( e => {
							this.closePopupModal()
						}, 100)
					})
				}
			}).catch( err => {
				console.log(err)
				this.modelForm.submitBtn = {
					loader: false,
					text: 'Simpan'
				}
				this.$toasted.error('Gagal menambah data, silahkan coba kembali!').goAway(3000)
			})
		},
		deleteItem (item) {
			this.$confirm({
				auth: false,
                message: "Anda yakin ingin menghapus data ini?",
                button: {
					no: 'Tidak',
                    yes: 'Ya'
				},
				callback: confirm => {
					if (confirm) {
						this.$store.dispatch({
							type: 'template/destroy',
							formInput: item
						}).then( res => {
							this.getDataList()
								.then( data => {
									this.modelTable.dataList = data.items
									this.modelTable.totalData = data.total
									
									this.$toasted.success('Sukses menghapus data').goAway(3000)
								})
						}).catch( err => {
							console.log(err)
							this.$toasted.error('Gagal menghapus data, silahkan coba kembali!').goAway(3000)
						})
					}
				}
			})
		},
		downloadItem (item) {
			this.$store.dispatch({
				type: 'template/fetch',
				formInput: {
					templateId: item.templateId,
					outputType: 'preview'
				}
			}).then( res => {
				let physicalFile = new File(
					[res], 
					item.templateName, 
					{ 
						type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document", 
						lastModified: Date.now() 
					}
				)
				let fileURL = window.URL.createObjectURL(physicalFile)

				let link = document.createElement("a")
				link.href = fileURL
				link.download = item.templateName+".docx"
				link.click()
				setTimeout(function() {
					window.URL.revokeObjectURL(fileURL)
				}, 100)

				this.previewDoc.title = item.templateName
				this.previewDoc.isLoad = true
			}).catch( err => {
				console.log(err)
				this.$toasted.error('Gagal memuat data, silahkan coba kembali!').goAway(3000)
			})
		},
		previewItem(item) {
			this.previewModalIsShow = true
			this.attachment.loader = true
			this.$store.dispatch({
				type:'template/fetch',
				formInput: {
					templateId:	item.templateId,
					outputType: 'thumbnail'
				}
			}).then(res => {
				let rawThumbnails = res.thumbnail_list
				let pageCount = res.thumbnail_list.length
				let thumbnails = []
				for (let i = 0; i < rawThumbnails.length; i++) {
					const blobRaw = rawThumbnails[i];
					let thumbnailBlob = blobRaw.replace("b'","").replace("'","")
					thumbnails.push(thumbnailBlob)
                }
                // Load fetched data
                this.attachment.pageCount = pageCount
                this.attachment.thumbnails = thumbnails
			}).catch( err => {
				var messageErr = err.response.data.error
				this.$toasted.error(messageErr).goAway(3000)
			}).finally( () => {
				this.attachment.loader = false
			})
		},
		closePopupPreviewModal() {
			this.previewModalIsShow = false
		},
		detailItem(item) {
			this.$store.dispatch({
				type:'template/fetch',
				formInput: {
					templateId:	item.templateId,
					outputType: 'details'
				}
			}).then(res => {
				this.detailData.templateName = res.result.template_name
				this.detailData.categoryName = res.result.template_category
				this.detailData.createdAt = res.result.created_at
				this.detailData.createdBy = res.result.created_by
				var field = ""
				res.result.template_field.forEach(item=> {
					field = field + "\""+item+"\", "
				})
				
				this.detailData.fieldData = field.substring(0, field.length-2);
			}).catch( err => {
				var messageErr = err.response.data.error
				this.$toasted.error(messageErr).goAway(3000)
			}).finally( () => {
				this.detailModalIsShow = true
			})
		},
		closePopupDetailModal() {
			this.detailModalIsShow = false
		}
	}
}
</script>

<style scoped></style>